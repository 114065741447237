import React from 'react';
import TaskSummary from './TaskSummary'; // ייבוא הקומפוננטה של סיכום משימות
import MonthlyExpensesTotal from './MonthlyExpensesTotal'; // ייבוא הקומפוננטה של ההוצאות החודשיות
import './MainPage.css'; // ייבוא העיצוב

const Main = () => {
  return (
    <div className="main-container">
      <h1>דשבורד ניהול</h1>
      <div className="summary-section">
        <div className="summary-box">
          <TaskSummary />
        </div>
        <div className="summary-box">
          <MonthlyExpensesTotal /> {/* הצגת סך ההוצאות החודשיות */}
        </div>
      </div>
    </div>
  );
};

export default Main;
