import React, { useState } from 'react';
import MonthlyExpenses from './MonthlyExpenses'; // ייבוא הקומפוננטה MonthlyExpenses
import AddExpenseModal from './AddExpenseModal'; // ייבוא קומפוננטת הפופאפ
import './MonthlyExpensesPage.css';

const MonthlyExpensesPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveNewExpense = (newExpense) => {
    // כאן תוכל לקרוא ל-API להוספת ההוצאה החדשה לשרת או לעדכן את הסטייט עם ההוצאה החדשה
    console.log('New expense:', newExpense);
  };

  return (
    <div className="expenses-page">
      <h1 className="page-title">ניהול הוצאות חודשיות קבועות</h1>
      
      {/* הסבר על הוצאות חודשיות */}
      <div className="expenses-explanation">
        <p>
          בעמוד זה תוכלו לעקוב ולנהל את ההוצאות החודשיות הקבועות שלכם בצורה נוחה ויעילה. 
          ניהול נכון של ההוצאות החודשיות יכול לעזור לכם להימנע מחריגות בתקציב ולהתכונן לתשלומים הבאים.
        </p>
        <p>
          הוצאות חודשיות קבועות הן תשלומים שעליכם לבצע מדי חודש עבור שירותים או מוצרים כמו חשמל, מים, אינטרנט, שכירות וכדומה.
          בעמוד זה תוכלו להוסיף, לעדכן ולצפות בהוצאות הקבועות שלכם, כדי לוודא שאתם תמיד בשליטה על התקציב החודשי.
        </p>
        <p>
          <strong>טיפים לניהול נכון של הוצאות חודשיות:</strong>
        </p>
        <ul>
          <li>הקפידו לתעד את כל ההוצאות הקבועות בתצורה מסודרת ומעודכנת.</li>
          <li>וודאו שהגדרתם תאריך תשלום לכל הוצאה כדי להימנע מאיחורים בתשלומים.</li>
          <li>הוסיפו הערות רלוונטיות לכל הוצאה כדי לזכור פרטים חשובים.</li>
          <li>נסו לבצע הערכה חודשית של ההוצאות הקבועות כדי לשפר את ניהול התקציב שלכם.</li>
        </ul>
      </div>
      {/* כפתור להוספת הוצאה חודשית חדשה */}
      <button className="add-expense-button" onClick={handleOpenModal}>
        הוסף הוצאה חדשה
      </button>

      {/* פופאפ הוספת הוצאה חדשה */}
      <AddExpenseModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveNewExpense}
      />

      {/* טבלת ההוצאות */}
      <MonthlyExpenses />
    </div>
  );
};

export default MonthlyExpensesPage;
