import React, { useState } from 'react';
import PrivateTasksTable from './PrivateTasksTable'; // ייבוא הקומפוננטה של הטבלה
import PrivateAddTasks from './PrivateAddTasks'; // ייבוא הקומפוננטה של הטופס להוספת משימה אישית
import './PrivateTasksPage.css';

const PrivateTasksPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // שליטה על הפופאפ

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="private-tasks-page">
      <h1>ניהול משימות אישיות</h1>

      {/* הסבר על המשימות האישיות */}
      <div className="tasks-explanation">
        <p>
          ברוכים הבאים לעמוד ניהול המשימות האישיות. כאן תוכלו לנהל את המשימות האישיות שלכם בצורה יעילה וממוקדת.
          הטבלה מטה מציגה את כל המשימות האישיות שקשורות לחשבון המשתמש הנוכחי, ומאפשרת לכם לעקוב אחריהן, לערוך אותן, או להוסיף הערות.
        </p>
        <p>
          <strong>טיפים לניהול משימות אישיות:</strong>
          <ul>
            <li>עדכנו את הסטטוס של כל משימה עם סיום הביצוע.</li>
            <li>הקפידו להגדיר תאריכים כדי להבטיח שלא תשכחו משימות חשובות.</li>
            <li>נסו להוסיף תיאורים והערות שיסייעו לזכור פרטים נוספים על המשימה.</li>
          </ul>
        </p>
      </div>

      {/* כפתור להוספת משימה אישית חדשה */}
      <button className="add-task-button" onClick={handleOpenModal}>
        הוסף משימה אישית חדשה
      </button>

      {/* פופאפ טופס המשימה האישית */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={handleCloseModal}>X</button>
            <PrivateAddTasks onClose={handleCloseModal} />
          </div>
        </div>
      )}

      {/* טבלת המשימות האישיות */}
      <PrivateTasksTable className="TasksTable" />
    </div>
  );
};

export default PrivateTasksPage;
