import React, { useState } from 'react';
import axios from 'axios'; // ייבוא axios
import './AddExpenseModal.css'; // סטיילים מותאמים

const AddExpenseModal = ({ isOpen, onClose, onSave }) => {
  const [newExpense, setNewExpense] = useState({
    expenseName: '',
    amount: '',
    paymentDate: '',
    notes: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExpense((prevState) => ({
      ...prevState,
      [name]: value || '',  // ודא שברירת המחדל היא מחרוזת ריקה אם לא הוזן כלום
    }));
  };

  const handleSaveClick = async () => {
    try {
      // שליחת בקשה לשרת להוספת הוצאה חדשה
      const response = await axios.post('/api/expenses', newExpense);
      console.log('Expense saved successfully:', response.data);
      onClose(); // סגור את הפופאפ לאחר השמירה
      setNewExpense({
        expenseName: '',
        amount: '',
        paymentDate: '',
        notes: '',
      });
    } catch (error) {
      console.error('Error saving expense:', error);
    }
  };

  if (!isOpen) return null; // אם הפופאפ לא פתוח, לא להציג אותו

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>הוספת הוצאה חודשית חדשה</h2>
        <div className="form-group">
          <label>שם ההוצאה</label>
          <input
            type="text"
            name="expenseName"
            value={newExpense.expenseName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>תשלום</label>
          <input
            type="number"
            name="amount"
            value={newExpense.amount}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>תאריך התשלום</label>
          <input
            type="date"
            name="paymentDate"
            value={newExpense.paymentDate}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>הערות נוספות</label>
          <textarea
            name="notes"
            value={newExpense.notes}
            onChange={handleInputChange}
          />
        </div>
        <div className="modal-actions">
          <button onClick={handleSaveClick}>שמור</button>
          <button onClick={onClose}>סגור</button>
        </div>
      </div>
    </div>
  );
};

export default AddExpenseModal;
