import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TasksTable.css';

const TasksTable = () => {
  const [tasks, setTasks] = useState([]); 
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [editedTask, setEditedTask] = useState({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchTasks();
    }, 1000); // קריאה לפונקציה כל שנייה

    return () => clearInterval(intervalId); // מנקה את ה-interval כאשר הקומפוננטה נהרסת
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/api/tasks');
      const tasksData = response.data;
  
      if (Array.isArray(tasksData)) {
        // מיון המשימות כך ש"לא בוצע" למעלה ו"בוצע" למטה
        const sortedTasks = tasksData.sort((a, b) => {
          if (a.status === 'לא בוצע') return -1;
          if (b.status === 'לא בוצע') return 1;
          return 0;
        });
        setTasks(sortedTasks);
      } else {
        setTasks([]);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
      alert('תקלה במילוי משימה');
    }
  };
  

  const handleEditClick = (task) => {
    setEditingTaskId(task.id);
    setEditedTask({ ...task });
  };

  const handleCancelClick = () => {
    setEditingTaskId(null);
    setEditedTask({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTask(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSaveClick = async (taskId) => {
    try {
      await axios.put(`/api/tasks/${taskId}`, editedTask);
      alert('משימה נערכה בהצלחה!');
      setEditingTaskId(null);
      setEditedTask({});
      fetchTasks(); 
    } catch (error) {
      console.error('Error updating task:', error);
      alert('בעיה בעדכון משימה');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'לא צוין תאריך';
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${day}-${month}-${year}`;
  };

  const getRowClass = (status) => {
    if (status === 'בוצע') return 'completed-task';
    if (status === 'בתהליך') return 'in-progress-task';
    if (status === 'לא בוצע') return 'not-completed-task';
    return '';
  };

  return (
    <div className="tasks-table-container">
      <table className="tasks-table">
        <thead>
          <tr>
            <th>שם המשימה</th>
            <th>מהות המשימה</th>
            <th>הערה נוספת</th>
            <th>תאריך ביצוע אחרון</th>
            <th>סטטוס</th>
            <th>משתמש</th>
            <th>אחראי</th>
            <th>פעולות</th> {/* פעולות נשאר אחרון */}
          </tr>
        </thead>
        <tbody>
          {tasks.length > 0 ? (
            tasks.map(task => (
              <tr key={task.id} className={getRowClass(task.status)}>
                <td>
                  {editingTaskId === task.id ? (
                    <input
                      type="text"
                      name="taskName"
                      value={editedTask.taskName || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    task.taskName || 'אין שם משימה'
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <textarea
                      name="taskDescription"
                      value={editedTask.taskDescription || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    task.taskDescription || 'אין תיאור משימה'
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <textarea
                      name="additionalNotes"
                      value={editedTask.additionalNotes || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    task.additionalNotes || 'אין הערות נוספות'
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <input
                      type="date"
                      name="dueDate"
                      value={editedTask.dueDate ? editedTask.dueDate.split('T')[0] : ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    task.dueDate ? formatDate(task.dueDate) : 'לא צוין תאריך'
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <select
                      name="status"
                      value={editedTask.status || ''}
                      onChange={handleInputChange}
                    >
                      <option value="בוצע">בוצע</option>
                      <option value="לא בוצע">לא בוצע</option>
                      <option value="בתהליך">בתהליך</option>
                    </select>
                  ) : (
                    task.status || 'לא צוין סטטוס'
                  )}
                </td>
                <td>{task.user || 'משתמש לא ידוע'}</td>
                <td>
                  {editingTaskId === task.id ? (
                    <select
                      name="responsible"
                      value={editedTask.responsible || 'אמיר'}
                      onChange={handleInputChange}
                    >
                      <option value="אמיר">אמיר</option>
                      <option value="מורל">מורל</option>
                    </select>
                  ) : (
                    task.responsible || 'לא צוין אחראי'
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <>
                      <button onClick={() => handleSaveClick(task.id)}>שמור</button>
                      <button onClick={handleCancelClick}>ביטול</button>
                    </>
                  ) : (
                    <button onClick={() => handleEditClick(task)}>ערוך</button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">לא נמצאו משימות</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TasksTable;
