import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PrivateTasksPage.css';

// פונקציה למיון משימות לפי סטטוס
const sortTasksByStatus = (tasks) => {
  return tasks.sort((a, b) => {
    const statusOrder = { 'לא בוצע': 1, 'בתהליך': 2, 'בוצע': 3 };
    return statusOrder[a.status] - statusOrder[b.status];
  });
};

const PrivateTasksTable = () => {
  const [personalTasks, setPersonalTasks] = useState([]);
  const [editingTaskId, setEditingTaskId] = useState(null);
  const [editedTask, setEditedTask] = useState({});

  useEffect(() => {
    const username = localStorage.getItem('username'); // שליפת שם המשתמש המחובר

    if (username) {
      const intervalId = setInterval(() => {
        fetchPersonalTasks(username); // שליפת המשימות האישיות לפי שם המשתמש כל שנייה
      }, 1000); // עדכון כל שנייה

      return () => clearInterval(intervalId); // ניקוי ה-interval כאשר הקומפוננטה נהרסת
    }
  }, []);

  const fetchPersonalTasks = async (username) => {
    try {
      const response = await axios.get(`/api/personal-tasks?username=${username}`);
      const tasksData = response.data;
      const sortedTasks = sortTasksByStatus(tasksData); // מיין את המשימות לפי סטטוס
      setPersonalTasks(sortedTasks);
    } catch (error) {
      console.error('שגיאה בשליפת משימות אישיות:', error);
      alert('נכשל בשליפת משימות אישיות');
    }
  };

  const handleEditClick = (task) => {
    setEditingTaskId(task.id);
    setEditedTask({ ...task });
  };

  const handleCancelClick = () => {
    setEditingTaskId(null);
    setEditedTask({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveClick = async (taskId) => {
    try {
      await axios.put(`/api/personal-tasks/${taskId}`, editedTask);
      alert('המשימה נערכה בהצלחה!');
      setEditingTaskId(null);
      setEditedTask({});
      const username = localStorage.getItem('username');
      fetchPersonalTasks(username); // עדכון המשימות האישיות
    } catch (error) {
      console.error('תקלה בעדכון משימה אישית:', error);
      alert('בעיה בעדכון משימה אישית');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'לא צוין תאריך';
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${day}-${month}-${year}`;
  };

  const getRowClass = (status) => {
    if (status === 'בוצע') return 'completed-task';
    if (status === 'בתהליך') return 'in-progress-task';
    if (status === 'לא בוצע') return 'not-completed-task';
    return '';
  };

  return (
    <div className="personal-tasks-table-container">
      <table className="personal-tasks-table">
        <thead>
          <tr>
            <th>שם המשימה</th>
            <th>מהות המשימה</th>
            <th>הערה נוספת</th>
            <th>תאריך ביצוע</th>
            <th>סטטוס</th>
            <th>פעולות</th>
          </tr>
        </thead>
        <tbody>
          {personalTasks.length > 0 ? (
            personalTasks.map((task) => (
              <tr key={task.id} className={getRowClass(task.status)}>
                <td>
                  {editingTaskId === task.id ? (
                    <input
                      type="text"
                      name="taskName"
                      value={editedTask.taskName || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    task.taskName || 'אין שם'
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <input
                      type="text"
                      name="taskDescription"
                      value={editedTask.taskDescription || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    task.taskDescription || 'אין תיאור'
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <input
                      type="text"
                      name="additionalNotes"
                      value={editedTask.additionalNotes || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    task.additionalNotes || 'אין הערות נוספות'
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <input
                      type="date"
                      name="dueDate"
                      value={editedTask.dueDate || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    formatDate(task.dueDate)
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <select
                      name="status"
                      value={editedTask.status || ''}
                      onChange={handleInputChange}
                    >
                      <option value="לא בוצע">לא בוצע</option>
                      <option value="בתהליך">בתהליך</option>
                      <option value="בוצע">בוצע</option>
                    </select>
                  ) : (
                    task.status || 'לא צוין סטטוס'
                  )}
                </td>
                <td>
                  {editingTaskId === task.id ? (
                    <>
                      <button onClick={() => handleSaveClick(task.id)}>שמור</button>
                      <button onClick={handleCancelClick}>ביטול</button>
                    </>
                  ) : (
                    <button onClick={() => handleEditClick(task)}>ערוך</button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">אין משימות זמינות</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PrivateTasksTable;
