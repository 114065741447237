import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PrivateAddTasks.css'; // ודא שהנתיב נכון

const PrivateAddTasks = ({ onClose }) => {
  const [taskName, setTaskName] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [status, setStatus] = useState('לא בוצע');
  const [username, setUsername] = useState('');

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post('/api/personal-tasks', {
            taskName,
            taskDescription,
            additionalNotes,
            dueDate,
            status,
            user: username, // המשימות משויכות למשתמש המחובר
          });
          

      if (response.status === 201) {
        alert('Personal task created successfully!');
        setTaskName('');
        setTaskDescription('');
        setAdditionalNotes('');
        setDueDate('');
        setStatus('לא בוצע');
        onClose();
      }
    } catch (error) {
      console.error('Error creating personal task:', error);
      alert('Failed to create personal task');
    }
  };

  return (
    <form className="private-task-form" onSubmit={handleSubmit}>

      <div>
        <label htmlFor="taskName">שם המשימה:</label>
        <input
          type="text"
          id="taskName"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="taskDescription">מהות המשימה:</label>
        <textarea
          id="taskDescription"
          value={taskDescription}
          onChange={(e) => setTaskDescription(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="additionalNotes">הערה נוספת:</label>
        <textarea
          id="additionalNotes"
          value={additionalNotes}
          onChange={(e) => setAdditionalNotes(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="dueDate">תאריך ביצוע אחרון:</label>
        <input
          type="date"
          id="dueDate"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="status">סטטוס:</label>
        <select
          id="status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="לא בוצע">לא בוצע</option>
          <option value="בוצע">בוצע</option>
          <option value="בתהליך">בתהליך</option>
        </select>
      </div>

      {/* כפתורי שלח וסגור בתחתית */}
      <div className="modal-actions">
        <button type="button" className="close-button" onClick={onClose}>סגור</button>
        <button type="submit" className="submit-button">שלח</button>
      </div>
    </form>
  );
};

export default PrivateAddTasks;
