import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MonthlyExpenses.css'; // Import your styling here

const MonthlyExpenses = () => {
  const [expenses, setExpenses] = useState([]); 
  const [editingExpenseId, setEditingExpenseId] = useState(null);
  const [editedExpense, setEditedExpense] = useState({});

  useEffect(() => {
    fetchExpenses(); // Fetch data initially

    // Polling every 10 seconds
    const interval = setInterval(() => {
      fetchExpenses();
    }, 1000); // 10,000 milliseconds = 10 seconds

    // Clear interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const fetchExpenses = async () => {
    try {
      const response = await axios.get('/api/expenses'); // API to fetch expenses data
      const expensesData = response.data;

      if (Array.isArray(expensesData)) {
        setExpenses(expensesData);
      } else {
        setExpenses([]);
      }
    } catch (error) {
      console.error('Error fetching expenses:', error);
      alert('Failed to fetch expenses');
    }
  };

  const handleEditClick = (expense) => {
    setEditingExpenseId(expense.id);
    setEditedExpense({ ...expense });
  };

  const handleCancelClick = () => {
    setEditingExpenseId(null);
    setEditedExpense({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedExpense(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSaveClick = async (expenseId) => {
    try {
      await axios.put(`/api/expenses/${expenseId}`, editedExpense); // API to update expenses
      alert('Expense updated successfully!');
      setEditingExpenseId(null);
      setEditedExpense({});
      fetchExpenses(); // Refresh expenses after update
    } catch (error) {
      console.error('Error updating expense:', error);
      alert('Failed to update expense');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'לא צוין תאריך';
    const [year, month, day] = dateString.split('T')[0].split('-');
    return `${day}-${month}-${year}`;
  };

  return (
    <div className="expenses-table-container" dir="rtl">
      <table className="expenses-table">
        <thead>
          <tr>
            <th>שם ההוצאה</th>
            <th>תשלום</th>
            <th>תאריך התשלום</th>
            <th>הערות נוספות</th>
            <th>פעולות</th>
          </tr>
        </thead>
        <tbody>
          {expenses.length > 0 ? (
            expenses.map(expense => (
              <tr key={expense.id}>
                <td>
                  {editingExpenseId === expense.id ? (
                    <input
                      type="text"
                      name="expenseName"
                      value={editedExpense.expenseName || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    expense.expenseName || 'לא צוין שם הוצאה'
                  )}
                </td>
                <td>
                  {editingExpenseId === expense.id ? (
                    <input
                      type="number"
                      name="amount"
                      value={editedExpense.amount || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    expense.amount || 'לא צוין תשלום'
                  )}
                </td>
                <td>
                  {editingExpenseId === expense.id ? (
                    <input
                      type="date"
                      name="paymentDate"
                      value={editedExpense.paymentDate ? editedExpense.paymentDate.split('T')[0] : ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    expense.paymentDate ? formatDate(expense.paymentDate) : 'לא צוין תאריך'
                  )}
                </td>
                <td>
                  {editingExpenseId === expense.id ? (
                    <textarea
                      name="notes"
                      value={editedExpense.notes || ''}
                      onChange={handleInputChange}
                    />
                  ) : (
                    expense.notes || 'אין הערות נוספות'
                  )}
                </td>
                <td>
                  {editingExpenseId === expense.id ? (
                    <>
                      <button onClick={() => handleSaveClick(expense.id)}>שמור</button>
                      <button onClick={handleCancelClick}>ביטול</button>
                    </>
                  ) : (
                    <button onClick={() => handleEditClick(expense)}>ערוך</button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">לא נמצאו הוצאות</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MonthlyExpenses;
