import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './MonthlyExpensesTotal.css'; // ייבוא קובץ העיצוב

const MonthlyExpensesTotal = () => {
  const [totalExpenses, setTotalExpenses] = useState(0); // התחלת ברירת מחדל ב-0

  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    try {
      const response = await axios.get('/api/expenses'); // קריאה ל-API של ההוצאות
      const expensesData = response.data;

      if (Array.isArray(expensesData)) {
        // חישוב סך כל ההוצאות: המרה למספר ולאחר מכן חיבור
        const total = expensesData.reduce((acc, expense) => acc + (Number(expense.amount) || 0), 0) * 1.00;
        setTotalExpenses(total);
      }
    } catch (error) {
      console.error('Error fetching expenses:', error);
      alert('Failed to fetch expenses');
    }
  };

  return (
    <div className="expenses-container">
      <h3>סך כל ההוצאות החודשיות</h3>
      <p>{totalExpenses.toFixed(2)}</p>
    </div>
  );
};

export default MonthlyExpensesTotal;
