import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TaskSummary.css'; // ייבוא קובץ העיצוב

const TaskSummary = () => {
  const [notCompletedCount, setNotCompletedCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchTasks();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/api/tasks');
      const tasksData = response.data;

      if (Array.isArray(tasksData)) {
        const notCompleted = tasksData.filter(task => task.status === 'לא בוצע').length;
        const completed = tasksData.filter(task => task.status === 'בוצע').length;

        setNotCompletedCount(notCompleted);
        setCompletedCount(completed);
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
      alert('Failed to fetch tasks');
    }
  };

  return (
    <div className="summary-container">
      <h3>סיכום המשימות</h3>
      <p>
        <span className="task-icon not-completed-icon"></span>
        מספר משימות שלא בוצעו: {notCompletedCount}
      </p>
      <p>
        <span className="task-icon completed-icon"></span>
        מספר משימות שבוצעו: {completedCount}
      </p>
    </div>
  );
};

export default TaskSummary;
