import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // ייבוא useNavigate לצורך ניתוב
import './Sidebar.css';

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // הגדרת useNavigate

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button className="Sidebar-toggle" onClick={toggleSidebar} aria-label="Toggle Sidebar">
        {isOpen ? 'X' : '≡'}
      </button>
      <div className={`Sidebar ${isOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <button className="Sidebar-button" onClick={() => navigate('/')}>
              ראשי
            </button>
          </li>
          <li>
            <button className="Sidebar-button" onClick={() => navigate('/tasks')}>
              משימות
            </button>
          </li>
          <li>
            <button className="Sidebar-button" onClick={() => navigate('/monthly-expenses')}>
              הוצאות חודשיות
            </button>
          </li>
          <li>
            <button className="Sidebar-button" onClick={() => navigate('/private-tasks')}>
              משימות אישיות
            </button>
          </li>
          
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
