import React, { useState } from 'react';
import TasksTable from './TasksTable';
import TaskForm from './AddTasksForm'; // ייבוא הקומפוננטה של הטופס
import './TasksPage.css';

const TasksPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // שליטה על הפופאפ

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="tasks-page">
      <h1>ניהול משימות</h1>

      {/* הסבר על המשימות */}
      <div className="tasks-explanation">
        <p>
          ברוכים הבאים לעמוד ניהול המשימות. כאן תוכלו לנהל את המשימות היומיומיות שלכם בצורה קלה ויעילה.
          מערכת ניהול המשימות מאפשרת לכם לעקוב אחרי התקדמות כל משימה, לערוך פרטים חשובים ולוודא שכל משימה מבוצעת בזמן.
          השתמשו בטבלה מטה כדי לראות את המשימות הנוכחיות, לערוך אותן, או להוסיף הערות נוספות במידת הצורך.
        </p>
        <p>
          <strong>איך לשפר את ניהול המשימות ביום יום:</strong>
          <ul>
            <li>הקפידו לעדכן את הסטטוס של כל משימה לאחר ביצועה.</li>
            <li>הגדירו תאריכי ביצוע אחרון כדי להבטיח שהמשימות לא יתעכבו.</li>
            <li>נסו לרכז משימות דומות יחד כדי לעבוד בצורה ממוקדת יותר.</li>
            <li>הוסיפו הערות נוספות כדי לזכור פרטים חשובים לכל משימה.</li>
          </ul>
        </p>
              {/* כפתור להוספת משימה חדשה */}
      <button className="add-task-button" onClick={handleOpenModal}>
        הוסף משימה חדשה
      </button>

      {/* פופאפ טופס המשימה */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={handleCloseModal}>X</button>
            <TaskForm onClose={handleCloseModal} />
          </div>
        </div>
      )}
      </div>
      {/* טבלת המשימות */}
      <TasksTable className="TasksTable" />
    </div>
  );
};

export default TasksPage;
