import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Tasks from './components/Tasks/TasksPage';
import Main from './components/Main/MainPage'; 
import Sidebar from './components/SideBar';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import UserGreeting from './components/Login/UserGreeting'; // ייבוא הקומפוננטה החדשה
import MonthlyExpensesPage from './components/MonthlyExpenses/MonthlyExpensesPage'; // ייבוא העמוד החדש
import PrivateTasksPage from './components/Tasks/PrivateTasksPage'; 


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // בודק אם המשתמש מחובר בעת טעינת האפליקציה
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const username = localStorage.getItem('username'); // הוספת בדיקה לשם המשתמש
    if (token && username) {
      setIsAuthenticated(true);
    }
  }, []);

const handleLogin = (token, username) => {
  console.log("Saving token and username to localStorage:", token, username); // בדיקה שה-token ושם המשתמש נשמרים
  localStorage.setItem('authToken', token); // שומר את ה-token ב-localStorage
  localStorage.setItem('username', username); // שומר את שם המשתמש ב-localStorage
  setIsAuthenticated(true);
};


  // פונקציה שתבצע התנתקות
  const handleLogout = () => {
    console.log("Logging out...");
    localStorage.removeItem('authToken'); // מוחק את ה-token מה-localStorage
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <AppContent
        isAuthenticated={isAuthenticated}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
      />
    </Router>
  );
}

// פונקציה זו תשתמש ב-useLocation ותוודא שהיא בתוך ה-Router
function AppContent({ isAuthenticated, handleLogin, handleLogout }) {
  const location = useLocation(); // כעת useLocation בתוך רכיב שהוגדר ב-Router
  
  return (
    <div className="App">
      {isAuthenticated && <Sidebar />} {/* הצגת Sidebar רק אם מחוברים */}
      
      {/* הצגת ה-header רק אם לא נמצאים במסלול ההתחברות */}
      {location.pathname !== '/login' && (
        <header className="App-header">
          <h1 className='HeaderLogo'>A&M</h1>
          {isAuthenticated && <UserGreeting />} {/* הצגת שם המשתמש */}
          {isAuthenticated && <button onClick={handleLogout}>Logout</button>} {/* כפתור התנתקות */}
        </header>
      )}

      <Routes>
        {/* במידה והמשתמש מחובר והוא מנסה לגשת ל-login, נשלח אותו ל-main */}
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/main" /> : <Login onLogin={handleLogin} />}
        />
        <Route path="/register" element={<Register />} />
        {isAuthenticated ? (
          <>
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/main" element={<Main />} />
            <Route path="/monthly-expenses" element={<MonthlyExpensesPage />} />
            <Route path="/" element={<Navigate to="/main" />} />
            <Route path="/private-tasks" element={<PrivateTasksPage />} /> {/* הוספת מסלול המשימות האישיות */}
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </div>
  );
}

export default App;
