// src/components/Login/UserGreeting.js
import React, { useEffect, useState } from 'react';

const UserGreeting = () => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  return (
    <div>
      {username ? <p>היי {username}!</p> : <p>ברוך הבא!</p>}
    </div>
  );
};

export default UserGreeting;
